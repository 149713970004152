<template>
  <div>
    <NuxtPage />
  </div>
</template>
<script setup>

import {onMounted} from "vue";

onMounted(() => {
  const current_version = '2.0.3'
  const version = useCookie('app-version', {
    maxAge: 60 * 60 * 24 * 365,
    path: '/'
  });

  if(version.value && version.value !== current_version) {
    version.value = current_version;
    document.location.reload();
  } else {
    version.value = current_version;
  }
})

</script>